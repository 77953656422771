import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";
const fitAddon = new FitAddon();

const OPTIONS_TERM = {
  useStyle: true,
  screenKeys: true,
  cursorBlink: true,
  cols: 200,
  theme: {
    background: "black",
  },
};
function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}
const socket = new WebSocket("ws://127.0.0.1:8080/");

// const socket = io("ws://127.0.0.1:8080", {
//   transports: ["websocket"],
// });
const TerminalComponent = () => {
  const terminalRef = useRef();
  let curr_line = "";

  useEffect(() => {
    console.log("Socker state" + " " + socket.readyState);
    if (!terminalRef || !terminalRef.current || !socket.readyState) {
      return;
    }

    // socket.emit("requestTerminal");
    function terminalHandler({ data }) {
      console.log("Terminal handler called");
      console.log(data);
      console.log("Terminal handler called");
      if (data instanceof ArrayBuffer) {
        console.error(data);
        console.log(ab2str(data));
        //term.write(ab2str(data));
        term.write(ab2str(data));
      }
    }

    // socket.on("terminal", );
    //terminalHandler();
    const term = new Terminal(OPTIONS_TERM);
    term.loadAddon(fitAddon);
    term.open(terminalRef.current);
    fitAddon.fit();

    term.onData((data) => {
      // socket.emit("terminalData", {
      //   data,
      // });
      curr_line += data;
      term.write(curr_line);
      socket.send(curr_line);
    });

    socket.send("\n");

    // term.onKey((data) => {
    //   socket.send(data);
    // });

    // socket.emit("terminalData", {
    //   data: "\n",
    // });
    socket.addEventListener("message", (event) => {
      let res;
      console.log("Message from server ", event.data);
      res = event.data;
      term.write(event.data);
    });
    // return () => {
    //   socket.off("terminal");
    // };

    return () => {};
  }, [terminalRef]);

  return (
    <div
      style={{ width: "60vw", height: "300px", textAlign: "left" }}
      ref={terminalRef}
    ></div>
  );
};

export default function Project() {
  // terminal.loadAddon(fitAddon);
  // terminal.open(containerElement);
  // fitAddon.fit();

  return (
    <>
      <TerminalComponent />
    </>
  );
}
